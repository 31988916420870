import { Cookies } from "react-cookie";
export const acceptConsent=()=> {

  document.getElementById("consent").style.display = "none"
  initializeAnalytics();
}
export const declineConsent=()=> {
   document.getElementById("consent").style.display = "none"
  setCookie('user_consent', 'false', 30);

}
function checkConsent() {
  const consent = document.cookie.split('; ').find(row => row.startsWith('user_consent=')).split('=')[1];
  if (consent === 'true') {
    console.log(document.getElementById("consent"));
    document.getElementById("consent").style.display = "none"
    initializeAnalytics();

  }
}
window.onload =  setTimeout(() => {
    if (!document.cookie.split('; ').find(row => row.startsWith('user_consent='))) {
       document.getElementsByClassName("cookie-consent")[0].style.display = "block"
    } else {
      checkConsent();
    }
}, 500);





const setCookie = (name, value, days) => {
  console.log("set cookie",name,value,days)
  let expires = "";
  if (days) {
    // const date = new Date();
    // date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    // expires = "; expires=" + date.toUTCString();
    const date = new Date();
date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // Adds 1 day in milliseconds
expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function deleteAllCookies() {
  console.log("all delete cookies")
  const cookies = new Cookies();
  const allCookies = cookies.getAll();

  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName, { path: '/' });
  });
}

export function getUserConsent() {
  const cookies = new Cookies();
  return cookies.get('user_consent');
}

export const initializeAnalytics = () => {
  // Ensure dataLayer is defined before the script loads
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }

  // Function to set cookies
  

  // Function to get cookies by name
  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Example: Set a cookie named 'userConsent' with value 'true' that expires in 30 days
  setCookie('user_consent', 'true', 30);
  setTimeout(() => {
    console.log("After 1 minute:", document.cookie); // Cookie should be gone
  }, 61000);

  // Load the Google Analytics script
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-XDCSJ9HJHC`;
  document.head.appendChild(script);

  // Initialize gtag after the script is loaded
  script.onload = () => {
    gtag('js', new Date());
    gtag('config', 'G-XDCSJ9HJHC');
    
    // Example: Read the 'user_consent' cookie
    const user_consent = getCookie('user_consent');
    console.log('User Consent:', user_consent);

    // Read Google Analytics cookies
    const gaCookie = getCookie('_ga');
    console.log('Google Analytics Cookie:', gaCookie);
  };
}


